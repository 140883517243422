* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

.main-nav {

    width: 100%;
    height: 8rem;

    background-color: #f5f5f56e;
}

.nav-container {

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    text-align: center;
    height: 9rem;

}

.img_div {
    margin-left: 10rem;
}

img {
    width: 16rem;
}

.elems_div {
    display: flex;
    position: relative;
    right: 10rem;

    gap: 3rem;

}

.elems_div .elems_h1_ass_cwm {
    color: #8000804f;
    font-size: 2rem;
}

.Profile_div {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.Profile_div .fa-user {
    color: white;
    padding: 4px 5px;
    background-color: #800080;
    border-radius: 50%;
    font-size: 1.6rem;
}

.Profile_div h1 {
    color: #800080;
    font-size: 2rem;
}

.Profile_div .fa-chevron-down {
    color: #800080;
    font-size: 1.8rem;
}

.middle_container {

    height: 92.3rem;

    display: grid;
    place-items: center;
}

.subset_middle {

    width: 78%;
    height: 100%;

}

.navigation_detail_div {
    position: relative;
    top: 4rem;
}

.navigation_detail_div p {
    font-size: 1.6rem;
 color: black;
 font-weight: 500;
}


.referal_wallet_parent {
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 8rem;
    gap: 1rem;

}

.referral_code_div p {
    color: #800080;
    margin-bottom: 1rem;
    font-size: 2rem;
}

.referal_code_value {

    display: flex;
    gap: 2rem;
    font-size: 1.8rem;
    border: 2px solid #8080806e;
    padding: 13px 17px;
  
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 1rem;

}

.referal_code_value span {
    font-weight: bolder;
}

.wallet_div {
    display: flex;
    justify-content: center;
   
    width: 18rem;
    padding: 14px 4px;
    border-radius: 2rem;
    box-shadow: 0px 0px 4px #8000804f;
}

.wallet_inner {


    align-items: center;
    width: 13.5rem;


}

.wallet_inner p {
    color: #800080;
    font-size: 2rem;
}

.wallet_inner h3 {
    font-size: 1.8rem;
}


.enroll_card_parent {


    height: 48rem;
    position: relative;
    top: 14rem
}

.friend_enroll_div h4 {
    font-size: 2.8rem;
    color: #800080
}

.friend_enroll_div span {
    color: #666464;
    font-weight: lighter;
}

.cards_parent {
    display: grid;

    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 24px;

  
    width: 100%;
    position: relative;
    top: 3rem;
}

.card1 {
    height: 28rem;
    border-radius: 1.5rem;


    background-image: linear-gradient(to right, #EEAEAE,
            #800080);
    background: linear-gradient(90deg, #ee983d, #611F67 100%);

}



.Name_date_parent {
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: relative;
    top: 2rem
}

.Name_div h3 {
    color: white;
    font-size: 2rem;
    position: relative;
    left: 2rem;
}

.date_div p {
    color: white;
    font-size: 1.9rem;
    position: relative;
    right: 2rem;
}

.courses_enrl_div p {
    color: white;
    font-size: 2rem;
    margin-top: 3rem;
    padding-left: 19px;
}

.course_detail_parent {
    display: flex;
    gap: 1.7rem;

    padding-left: 28px;
    margin-top: 1.4rem;
}

.course_detail_parent p {
    padding: 6px 11px;
    border: 1px solid white;
    border-radius: 2.8rem;
    font-size: 2rem;
    color: white;
}

.referal_amount_div {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding-left: 18px;
    margin-top: 2rem;
}

.referal_amount_div p {
    color: white;
    font-size: 2rem;

}

.referal_amount_div h1 {
    color: white;
    font-size: 2.4rem;
}

.tc_div {
    position: relative;
    top: 12rem;
}

.tc_div p {
    color: #800080;
    font-size: 3rem;
}


@media (max-width: 1560px) {

    .course_detail_parent p {
        padding: 6px 11px;

        font-size: 1.5rem;

    }

    .Name_div h3 {

        font-size: 1.7rem;


    }

    .date_div p {

        font-size: 1.7rem;

    }

    .course_detail_parent p {

        font-size: 1.7rem;

    }

    .referal_amount_div h1 {

        font-size: 2.2rem;
    }
}

@media (max-width: 1417px) {

    .cards_parent {
        display: grid;

        grid-template-columns: 1fr 1fr;
        row-gap: 14px;


    }




}

@media (max-width: 944px) {
    .elems_div .elems_h1_ass_cwm {
        display: none;
    }

}

@media (max-width: 944px) {
    .cards_parent {
        display: grid;

        grid-template-columns: 1fr;
        row-gap: 15px;


    }

    .tc_div {
      position: relative;
     top:5rem;
  
      
    }

   
   
}

@media (max-width: 774px) {

    .img_div {
        margin-left: 4rem;
    }

    img {
        width: 16rem;
    }

    .elems_div {

        position: relative;
        right: 4rem;


    }

}

@media (max-width: 670px) {
    .wallet_inner p {
        color: #800080;
        font-size: 1.7rem;
    }

    .wallet_inner h3 {
        font-size: 1.6rem;
    }

    .wallet_div {

        width: 16rem;
        padding: 13px 3px;
        border-radius: 1rem;
    }

    .wallet_inner {


        align-items: center;
        width: 12rem;
    }

    .referal_code_value {


        font-size: 1.5rem;

        padding: 13px 12px;

   

    }

    .referral_code_div p {

        font-size: 1.7rem;
    }


}



@media (max-width: 490px) {
    .wallet_inner p {

        font-size: 1.6rem;
    }

    .wallet_inner h3 {
        font-size: 1.6rem;
    }

    .wallet_div {

        width: 14rem;
        padding: 13px 2px;
        border-radius: 1rem;
    }

    .wallet_inner {


        align-items: center;
        width: 10.3rem;
    }

    .referal_code_value {


        font-size: 1.4rem;

        padding: 13px 10px;



    }

    .referral_code_div p {

        font-size: 1.6rem;
    }

    .img_div {
        margin-left: 2rem;
    }

    img {
        width: 15rem;
    }

    .elems_div {

        position: relative;
        right: 2rem;


    }

    .friend_enroll_div h4 {
        font-size: 2.6rem;

    }


}

@media (max-width: 465px) {
    .wallet_inner p {

        font-size: 1.5rem;
    }

    .wallet_inner h3 {
        font-size: 1.5rem;
    }

    .wallet_div {

        width: 13.5rem;
        padding: 13px 2px;
       position: relative;
       left: 3rem;
    }

    .wallet_inner {


        align-items: center;
        width: 10.2rem;
    }
}

@media (max-width: 442px) {

    .course_detail_parent p {
        padding: 6px 9px;

        font-size: 1.4rem;

    }

}


@media (max-width: 423px) {
    .img_div {
        margin-left: 1rem;
    }

    img {
        width: 14rem;
    }

    .elems_div {

        position: relative;
        right: 1rem;


    }

    .navigation_detail_div p{
        font-size: 1.5rem;

    }

    .Profile_div .fa-user {

        font-size: 1.5rem;
    }

    .Profile_div h1 {

        font-size: 1.8rem;
    }

    .Profile_div .fa-chevron-down {

        font-size: 1.7rem;
    }


}